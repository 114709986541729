<template>
  <v-sheet
    class="grey lighten-3 pa-4 my-3"
    rounded="lg"
  >
    <v-row dense>
      <v-col
        cols="12"
        md="2"
      >
        <v-text-field
          v-model="location.type"
          label="Tipo"
          placeholder="Tipo"
          :loading="isLoading"
          :disabled="isDisabled"
          outlined
          rounded
          dense
          hide-details
        />
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <v-text-field
          v-model="location.zipcode"
          v-mask="'#####-###'"
          label="CEP"
          placeholder="xxxxx-xxx"
          append-icon="fas fa-search-location"
          outlined
          rounded
          dense
          hide-details
          @blur="getAddress"
        />
      </v-col>
      <v-col
        cols="12"
        md="7"
      >
        <v-text-field
          v-model="location.public_place"
          label="Endereço"
          placeholder="Digite o nome da rua"
          :loading="isLoading"
          :disabled="isDisabled"
          outlined
          rounded
          dense
          hide-details
        />
      </v-col>
      <v-col
        cols="12"
        md="2"
      >
        <v-text-field
          v-model="location.state"
          label="UF"
          placeholder="SP"
          :loading="isLoading"
          :disabled="isDisabled"
          outlined
          rounded
          dense
          hide-details
        />
      </v-col>
      <v-col
        cols="12"
        md="5"
      >
        <v-text-field
          v-model="location.city"
          label="Cidade"
          placeholder="Digite o nome da cidade"
          :loading="isLoading"
          :disabled="isDisabled"
          outlined
          rounded
          dense
          hide-details
        />
      </v-col>
      <v-col
        cols="12"
        md="5"
      >
        <v-text-field
          v-model="location.neighborhood"
          label="Bairro"
          placeholder="Digite o nome do bairro"
          :loading="isLoading"
          :disabled="isDisabled"
          outlined
          rounded
          dense
          hide-details
        />
      </v-col>
      <v-col
        cols="12"
        md="2"
      >
        <v-text-field
          v-model="location.number"
          label="N°"
          outlined
          rounded
          dense
          hide-details
        />
      </v-col>
      <v-col
        cols="12"
        md="10"
      >
        <v-text-field
          v-model="location.complement"
          label="Complemento"
          placeholder="Complemento"
          :loading="isLoading"
          :disabled="isDisabled"
          outlined
          rounded
          dense
        />
      </v-col>
      <v-col
        cols="12"
        md="12"
      >
        <v-btn
          rounded
          outlined
          color="primary"
          @click="$emit('removeLocation')"
        >
          Remover Endereço
        </v-btn>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
export default {
  props: {
    location: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    isLoading: false,
    isDisabled: false,
  }),
  methods: {
    getAddress() {
      this.isLoading = true

      this.$api
        .get(`https://cep.awesomeapi.com.br/json/${this.location.cep}`)
        .then((res) => {
          this.location.city = res.data.city
          this.location.district = res.data.district
          this.location.uf = res.data.state
          this.location.address = res.data.address
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>
